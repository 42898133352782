import React from "react"
import Columns from "../components/Columns/Columns"
import Layout from "../components/Layout/Layout"
import MainSection from "../components/MainSection/MainSection"
import Section from "../components/Section/Section"
import Seo from "../components/Seo/Seo"
import "../styles/privacy-policy.scss"
import TextWithIcon from "../components/TextWithIcon/TextWithIcon"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo
        title="Privacy Policy"
        description="Resistant AI analyses the hidden relationships between identities and transactions to make calls between legitimate and criminal activities more precise."
      />

      <Section title="Privacy Policy" id="privacy-policy">
        <div className="mt-9 justified">
        <p>
          This Privacy <strong>Policy</strong> (hereinafter the “Policy”) governs the terms and conditions under which <strong>Resistant AI s.r.o.</strong>, with its
          registered office at Voršilská 139/5, Nové Město, 110 00 Praha 1, Identification No.: 078 25 439, registered in the Commercial
          egister maintained by the Municipal Court in Prague, File No. C 308250 (hereinafter the <strong>“Company”</strong>), processes personal data
          in connection with the activities and services concerning the operation of the website resistant.ai run and managed by the
          Company (hereinafter the <strong>“Website”</strong>).
        </p>
        <p>
          We are serious about protecting your personal data and want you to feel safe and comfortable while browsing our Website.
          We therefore respect the confidentiality of your personal data and always proceed in accordance with the provisions of data
          protection legislation, in particular, Regulation (EU) 2016/679 of the European Parliament and of the Council (General Data
          Protection Regulation, hereinafter the <strong>“GDPR”</strong>), and follow this Policy.
        </p>
        <p>
          With respect to the above, the Company uses this Policy to inform you about how, for what purposes and to what extent the Company
          uses your personal data and what information about you as a user of the Websites the Company may process.
        </p>

        <h3>1. Definitions</h3>

        <p><strong>“Personal data”</strong> means any information relating to a data subject;</p>
        <p><strong>“Controller”</strong> means the natural or legal person, public authority, agency or other body which, alone or jointly with others,
          determines the purposes and means of the processing of personal data;
        </p>
        <p>
          <strong>“Data subject”</strong> means any identified or identifiable person who can be identified, directly or indirectly, in
          particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one
          or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person;
        </p>
        <p>
          <strong>“Data processor”</strong> means a natural or legal person, public authority, agency or other body which processes personal
          data on behalf of the controller;
        </p>
        <p><strong>“Processing”</strong> means any operation or set of operations which is performed on personal data or on sets of personal data,
          whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval,
          consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
        </p>

        <h3>2. Basic information about personal data processing conducted by the Company</h3>

        <p>
          We always process your personal data lawfully, fairly, in a transparent manner and for specified, explicit and legitimate purposes.
          We process personal data only to the minimum necessary extent and we keep them in a form which permits your identification for no
          longer than is necessary vis-à-vis the purpose of the processing.
        </p>
        <p>We process your personal data in a manner that sufficiently ensures their integrity and confidentiality, i.e., by appropriate technical
            or organizational measures and appropriate protection against unauthorized or unlawful processing and against loss, destruction or damage.
            We take care to ensure that personal data that are inaccurate, having regard to the purpose for which we process them, are erased or
            rectified without delay.
        </p>
        <p>
            We respect the principle of refraining from personal data processing and the principle of data minimization. We therefore only retain
            your personal data if it is necessary in order to achieve the purpose of the processing or for various retention periods specified by law.
            The relevant data are erased in accordance with the law if the relevant purpose ceases to exist as a result of the withdrawal of your consent
            and/or upon the expiration of the lawful retention period.
        </p>
        <p>
          For the above reasons, we use computer security such as a firewall and data encryption to operate our Website. We have implemented adequate
          physical, electronic and procedural safeguards and use reliable IT service providers. However, given the nature of the internet, we would
          like to bring to your attention the fact that certain security gaps may exist in the transmission of personal data via the internet (e.g.
          in communication via e-mail) and that full protection of personal data preventing third party access is impossible.
        </p>

        <h3>3. Legal ground, purpose and extent of the processing of your personal data</h3>
        <p>Our Company may process your personal data for the following legal grounds and for the following purposes:</p>
        <p><strong>a. Provision and improvement of and support for our Website</strong></p>
        <p>Our Company processes various information about your online activity, e.g. the time of access to our Website, the time spent on our website, conversions (i.e. completed activity on our Website), etc., for the purposes of technical support and improvement of our Website as well as monitoring of functionalities thereof (for details regarding the extent of the data being processed see Article 4 (c) - d) of this Policy).</p>
        <p>For this purpose of personal data processing, our Company processes your personal data under the lawful ground of legitimate interest (operation of the Website, statistical purposes and data security).</p>
        <p><strong>b. Cookies</strong></p>
        <p>Our Company uses various cookie files, which may contain your personal data (e.g. your IP address or the configuration of your browser and computer). Our Company uses cookies on the basis of your consent that you express via the cookies settings displayed to you in a banner during your first visit to our Website. This consent can be subsequently amended / withdrawn via your web browser settings (to the extent allowed by the respective browser).</p>
        <p><strong>c. Newsletter</strong></p>
        <p>If you subscribe to our newsletter, our Company processes your e-mail address in order to provide you with the respective news. Our Company processes your e-mail address for the newsletter purposes based on your consent, which you can withdraw at any time.</p>
        <p><strong>d. Contact form</strong></p>
        <p>If you use the contact form on our Website for your queries relating to our Company’s services, we process the personal data provided by you to the following extent: your name, e-mail address, IP address, customer ID, as well as any other personal data that inquirer states in the body of the contact form.</p>
        <p>For these purposes of personal data processing, our Company processes your personal data under the lawful ground of negotiation and performance of a contract (negotiation and performance of an agreement between our Company and yourself including customer support) and legitimate interest (general, sales and technical related communication between our Company and yourself).</p>
        <h3>4. What sources do we collect your personal data from?</h3>
        <p>We collect your personal data from multiple sources:</p>
        <p><strong>a. Data collected from you as the data subject</strong></p>
        <p>We primarily collect the personal data about you that you provide voluntarily yourself (e.g. in the consent to the personal data processing) or the data that we acquire in connection with your queries, comments, requests, etc.</p>
        <p><strong>b. Cookies</strong></p>
        <p>Your personal data also come from cookies, which are small text files that are created by the web server and saved into your computer through your browser. We use cookies in connection with your activity on our Website and with other online activities. Cookies help our Company make our online services more user friendly, efficient and secure, and may also be used to implement certain user features.</p>
        <p>In particular, our Company uses the following types of cookie files:</p>
        <li>Persistent</li>
        <li>Third party</li>
        <p><strong>c. Web analytics tools</strong></p>
        <p>Our Website also uses online web analytics services and social plugins for the purposes of continuously optimising the user interface and to maximise its user friendliness. These automatically collected data are not tied to data from other sources. However, we reserve the right to back-check these data if we are notified of a specific risk of their misuse.</p>
        <p><strong>d. Log files</strong></p>
        <p>Our Company and the providers hosting our Website use their databases to log data about each access to the server where the online presentation of our Website is located. Hence whenever you visit our Website, your web browser automatically sends certain information to the Website server to enable communication between your browser and the server. This information is then saved in the so-called log files.</p>
        <p>The log files mainly contain information about:</p>
        <li className="list__type__none">i. the type, version and preferred languages of the browser you are using;</li>
        <li className="list__type__none">ii. the type and version of your operating system;</li>
        <li className="list__type__none">iii. the website you have come to our Website from;</li>
        <li className="list__type__none">iv. the website you leave our Website for;</li>
        <li className="list__type__none">v. the IP address of your computer;</li>
        <li className="list__type__none">vi. the date, time and success of finding our Website;</li>
        <li className="list__type__none">vii. when you accessed the Website and how long you stayed on the Website;</li>
        <li className="list__type__none">viii. the volume of data transmitted between the server and your computer;</li>
        <li className="list__type__none">ix. the number of visits and average time spent on our website.</li>
        <p>For security reasons, the data in the log files are kept for only as long as the purpose of the processing lasts. If the given purpose of retaining the log files no longer exists, the log files are deleted immediately. The data whose further retention is necessary on the grounds of providing evidence will not be deleted until the relevant case is closed.
        </p>
        <p><strong>e. Google Analytics</strong></p>
        <p>Our Company uses the Google Analytics plugin from Google Inc., 1600 Amphitheatre Parkway Mountain View, California 94043, USA (hereinafter <strong>“Google”</strong>). The Google Analytics plugin analyses your use of our Website as well as other online activities and connects the data generated by Google Analytics with the cookies of our Company. However, as our Company has activated the IP address anonymisation feature, your IP addressed is anonymised once the data enters the Google Analytics collection network.</p>
        <p>You can prevent the collection of the data generated by the cookies files and their use by Google Analytics by downloading and installing the plugin available at <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>. For detailed information about the terms and conditions and the privacy policy please see www.google.com/analytics/terms/gb.html or https://policies.google.com/privacy.</p>
        <p><strong>f. Google Ads</strong></p>
        <p>Our company also uses Google Ads from Google, which helps us determine how effective the clicks on our ads with regard to the desired customer behaviour – e.g. purchases, phone calls, app downloads, newsletter subscriptions, etc. For detailed information about the terms and conditions and the privacy policy please see <a href="https://support.google.com/google-ads/">https://support.google.com/google-ads/</a>.</p>
        <p><strong>g. LeadLander</strong></p>
        <p>This site uses a third-party application from so called Upland LeadLander.com to track users’ IP addresses and
          search-related data in order to evaluate visits to, and monitor use of, the Site. The information generated by the
          cookie about your use of this website is transmitted to a server in the US and stored there. We use the information
          to compile reports and to help us improve the Site. These anonymous cookies, provided by leadlander.com, help us to
          measure the effectiveness of our marketing campaigns. This helps us to ensure we are broadcasting the correct message
          in our marketing collateral and to optimize the process of obtaining the information you require.  LeadLander may
          associate a user’s IP address with a user’s company affiliation, but does not associate any user’s IP address with
          the user’s name or email address. LeadLander will place a cookie containing a random number called “trackalyzer” on
          the user’s computer. The random number and the user’s IP address is stored in LeadLander’s external database.
          LeadLander will honor “Do Not Track” requests if set on a user’s browser. LeadLander does not retain any user or visit
          data in web logs or log files, but uses pixel tag images to record which pages of the Site are visited. This cookie is
          persistent so that data can be linked over multiple sessions. LeadLander’s privacy policy can be viewed
          at <a href="https://www.leadlander.com/privacy.html">https://www.leadlander.com/privacy.html</a>.
        </p>
        <p><strong>h. LinkedIn</strong></p>
        <p>Our website uses features of the LinkedIn social network. Provider is the LinkedIn Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA. Each time you access one of our pages that contains LinkedIn’s features, a connection is established to a LinkedIn server. LinkedIn is informed that you have visited our website with your IP address. If you click on the <strong>“Recommend-Button”</strong> of LinkedIn and you are logged in to your account with LinkedIn, LinkedIn is able to assign your visit to you and your user account. We would like to point out that, as the provider of these pages, we have no knowledge of the content of the transmitted data and their use by LinkedIn.
        For more information, see the LinkedIn privacy statement at: <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a></p>
        <p><strong>i. HubSpot</strong></p>
        <p>We have HubSpot tracking code on our website which sets a number of tracking cookies when a visitor lands on our website so we can understand their behavior better.</p>

        <p>If you are interested in the specific source of the processing of your personal data, you may use the contact details provided below to submit your query to us.</p>
        <h3>5. Recipients of your personal data</h3>
        <p>Subject to the applicable legislation, our Company transmits your personal data to third parties (recipients) for the purpose of further processing, which parties process the personal data under a data processing agreement.</p>
        <p>In particular, the above-mentioned recipients of the personal data include the following categories of recipients:</p>
        <li>entities from to the company group to which our Company belongs;</li>
        <li>administrators of our Website;</li>
        <li>IT services providers and relay server operators;</li>
        <li>payment services providers;</li>
        <li>external legal counsel and debt collection agencies;</li>
        <li>social network operators;</li>
        <li>marketing software providers;</li>
        <li>law enforcement authorities, courts, administrative authorities where our Company is so obliged by the legislation.</li>

        <h3>6. Personal data processing period</h3>
        <p>We process personal data only for as long as the personal data are necessary for the achievement of the purpose of their processing. As soon as the purpose of the processing is achieved and there are no other purposes for which we would be authorised to process the personal data, we erase the personal data.</p>
        <p>As regards personal data processed on the basis of your consent, we also erase your personal data if you withdraw your consent to the processing of your personal data.</p>
        <p>If we process the personal data on the basis of legitimate interest and you object to their processing and unless there are any prevailing legitimate grounds for the processing, your personal data will also be erased after we inform you about this fact.</p>
        <p>We process your personal data for the following specific periods:</p>
        <p><strong>a. Provision and improvement of and support for our Website</strong></p>
        <p>After you leave our Website, we delete or anonymise your IP address except where (i) the obligation to retain the IP address is specified by the law or by an order of an administrative authority or of a court, (ii) we need it to make and enforce any claim we may have against you or to defend us from any claims made by you (for the term of limitation).</p>
        <p><strong>b. Cookies</strong></p>
        <p>Our Website uses cookies, which are automatically removed after you close your web browser (session cookies). However, our Company also occasionally uses <strong>“persistent cookies”</strong>, which remain in your device until they expire or are deleted manually. The period of the processing of such persistent cookies depends on your computer / internet browser settings.</p>
        <p><strong>c. Newsletter</strong></p>
        <p>If you consent to receive news from our newsletter, we process your e-mail address until you unsubscribe from the newsletter.</p>
        <h3>7. Transfer of data to third countries</h3>
        <p>Your personal data might be transferred to relay server operators and other IT services operators outside the European Union and the EEA countries. In such case, our Company has taken adequate measures to secure that your personal data are processed in compliance with GDPR.</p>
        <h3>8. Your rights as a data subject</h3>
        <p>As a data subject you have the following rights:</p>
        <li className="list__type__none">i. Right to be informed</li>
        <li className="list__type__none">ii. Right of access to personal data</li>
        <li className="list__type__none">iii. Right to rectification</li>
        <li className="list__type__none">iv. Right to erasure (‘right to be forgotten’)</li>
        <li className="list__type__none">v. Right to restriction of processing</li>
        <li className="list__type__none">vi. Right to data portability</li>
        <li className="list__type__none">vii. Right to object</li>
        <li className="list__type__none">viii. Right not to be subject to a decision based solely on automated processing, including profiling</li>
        <li className="list__type__none">ix. The right to lodge a complaint with the Office for Personal Data Protection or other relevant supervisory authority in connection with the processing of personal data</li>
        <p>Except for the right to lodge a complaint with the Office for Personal Data Protection or other relevant supervisory authority in
        connection with the processing of personal data, you may exercise all the rights listed above by making a request using any manner
        specified below. We will inform you about the action taken on your request within one month of receipt of your request and no later
        than within three months of receipt of your request if that period has been extended on reasoned grounds.</p>
        <p><strong>a. Right to be informed</strong></p>
        <p>We will always use this Policy and other notice, if required, to inform you in a concise, transparent, intelligible and easily accessible manner about the processing
        of your personal data from the moment we obtain personal data from you. If we do not obtain personal data from you directly, we will provide you the information within
        a reasonable period after obtaining them, but within one month at the latest, unless the provision of such information proves impossible or would involve a disproportionate effort
        (e.g. we have no contact information about you).
        </p>
        <p><strong>b. Right of access to personal data</strong></p>
        <p>You have the right to obtain from us confirmation as to whether or not personal data concerning you are being processed. If we process your personal data, we will provide you with the information that you request or that we are required to provide by the law.</p>
        <p>If we do process personal data about you, we will provide you with a copy of the personal data which are subject to the processing free of charge. For any further copies requested by the data subject, we may charge a reasonable fee based on the administrative costs of making such copies. The right to obtain a copy of the personal data undergoing processing shall not adversely affect the rights and freedoms of others.</p>
        <p>Where you make the request by electronic means, the information will be provided in a commonly used electronic form, unless otherwise requested by you.</p>
        <p><strong>c. Right to rectification</strong></p>
        <p>You have the right to the rectification of any inaccurate personal data concerning you and, taking into account the purposes of the processing, to have incomplete personal data completed, including by means of providing a supplementary statement. We communicate any rectification of personal data to each recipient to whom the personal data have been disclosed, unless this proves impossible or involves disproportionate effort. We will inform about those recipients if you request it.</p>
        <p><strong>d. Right to erasure / right to be forgotten</strong></p>
        <p>We will erase your personal data without undue delay where one of the following grounds applies:</p>
        <li className="list__type__none">i. your personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed;</li>
        <li className="list__type__none">ii. you withdraw the consent on which the processing is based and there is no other legal ground for their processing;</li>
        <li className="list__type__none">iii. you object to the processing of your personal data and there are no overriding legitimate grounds for the processing or you object to the processing of your personal data for the purposes of direct marketing;</li>
        <li className="list__type__none">iv. your personal data have been unlawfully processed;</li>
        <li className="list__type__none">v. your personal data have to be erased to comply with a legal obligation under European Union or Member State law to which the Company is subjected;</li>
        <li className="list__type__none">vi. your personal data have been collected in relation to the offer of information society services to a child.</li>
        <p>The above does not apply to the extent that the processing of the personal data is necessary:</p>
        <li className="list__type__none">i. for exercising the right of freedom of expression and information;</li>
        <li className="list__type__none">ii. for compliance with a legal obligation imposed by European Union or Member State law which requires processing and to which the Company is subject to or for the performance of a task carried out in the public interest or in the exercise of official authority vested in the Company;</li>
        <li className="list__type__none">iii. for reasons of public interest in the area of public health;</li>
        <li className="list__type__none">iv. for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes in so far as the exercise of the right to erasure is likely to render impossible or seriously impair the achievement of the objectives of that processing;</li>
        <li className="list__type__none">v. for the establishment, exercise or defence of legal claims.</li>
        <p>We communicate any erasure of personal data to each recipient to whom the personal data have been disclosed, unless this proves impossible or involves disproportionate effort. We will inform you about the recipients of the personal data which have been notified if you request it.</p>
        <p>If we refuse to erase your personal data based on the exemptions specified above, we will inform you thereof within one month of receipt of the request, reason the grounds for the application of the exemptions, and instruct you in accordance with the requirements of the applicable laws and regulations.</p>
        <p><strong>e. Right to restriction of processing</strong></p>
        <p>You have the right to restrict your personal data processing in the following cases:</p>
        <li className="list__type__none">i. the accuracy of the personal data is contested by you; in this case, the processing is restricted for a period enabling us to verify the accuracy of the personal data;</li>
        <li className="list__type__none">ii. the processing is unlawful and you oppose the erasure of the personal data and request the restriction of their use instead;</li>
        <li className="list__type__none">iii. we no longer need the personal data for the purposes of the processing, but they are required by you for the establishment, exercise or defence of legal claims;</li>
        <li className="list__type__none">iv. you have exercised your right to object the processing of the personal data; in this case, the processing is restricted until we
            determine whether our legitimate grounds override your legitimate interests.
        </li>
        <p>
          As a result of restricted processing of personal data, we may continue to store the relevant personal data but they may only be processed
          with your consent or for the establishment, exercise or defence of legal claims or for the protection of the rights of another natural or
          legal person or for reasons of important public interest of the European Union or of a Member State. In such cases you will be informed
          before the restriction of processing is lifted.
        </p>
        <p><strong>f. Right to data portability</strong></p>
        <p>You have the right to receive your personal data processed by us provided that one of the following conditions is met:</p>
        <li className="list__type__none">i. the personal data are processed for a specific purpose on the basis of your consent;</li>
        <li className="list__type__none">ii. the personal data is processed by automated means;</li>
        <li className="list__type__none">iii. if the personal data are from a special category processed for one or more specified purposes on the basis of your explicit consent; or</li>
        <li className="list__type__none">iv. the processing of the personal data is necessary for the performance of a contract to which you are a party or in order to execute
          precautions accepted at your request prior to entering into a contract.
        </li>
        <p>
          The personal data provided to our Company is deemed to include data which you have disclosed to us directly,
          intentionally and actively, e.g. by means of completing a form, as well as personal data generated on the basis
          of your activity on our Website, e.g. the location data, application login dates, etc.
        </p>
        <p>
          We will provide the data in a structured, commonly used and machine-readable format. You may transmit the personal
          data obtained by us to another data controller. In your request, you may choose whether we should provide the personal
          data to you or whether you will exercise your right to have your personal data transmitted directly from us to another
          controller/processor, where technically feasible.
        </p>
        <h3>g. Right to object</h3>
        <p>
          As regards the processing of personal data under the legal ground of legitimate interest of our Company, you may object to
          the processing of your personal data on grounds relating to your particular situation described in the objection. You may
          object by using the contact details provided below.
        </p>
        <p>
          After we receive your objection we will cease the processing of (but will still keep) your personal data to assess whether we
          have compelling legitimate grounds for their processing which override your interests, rights and freedoms, or whether we need
          your personal data for the establishment, exercise or defence of our legal claims. If we conclude that we do have such grounds,
          we will inform you accordingly, including advising you on your options for further defence, and we will continue to process such
          personal data. On the contrary, if we conclude that we do not have sufficient grounds for the processing of the personal data,
          we shall inform you accordingly and erase such personal data.
        </p>
        <p>
          You have the right to object at any time to the processing of personal data for direct marketing purposes.
        </p>
        <h3>h. Right not to be subject to a decision based solely on automated processing, including profiling</h3>
        <p>
          We process your personal data with respect for your right not to be subject to a decision based solely on automated processing
          which significantly affects you, including profiling (i.e. any form of automated processing of personal data consisting of the
          use of personal data to analyse, predict or evaluate certain aspects concerning you – e.g. your economic situation, interests, etc.).
        </p>
        <p>
          However, in controlling your personal data, we may make you a subject of an automated decision if such a decision is necessary for
          entering into, or conclusion of, a contract between you and us, or is authorised by European Union or Czech law which also lays down
          suitable measures to safeguard the data subject’s rights and freedoms and legitimate interests, or is based on your explicit consent.
        </p>
        <h3>i. Right to file a complaint</h3>
        <p>If you disagree with the way we have handled your request, you may file a complaint with the Office for Personal Data Protection:</p>
        <li className="list__type__none">i. by electronic means at posta@uoou.cz;</li>
        <li className="list__type__none">ii. by phone at +420 234 665111; or</li>
        <li className="list__type__none">iii. in writing to Pplk. Sochora 27, 170 00 Praha 7, Czech Republic, or with another competent supervisory authority
        of a Member State.</li>
        <h3>9. Exercising your rights and obtaining more information about the processing of your personal data</h3>

        <p>In order to exercise your rights specified above or make any queries regarding this Policy or the processing of your
        personal data, please use the following contact details:</p>

        <p>Postal address: Voršilská 139/5, Nové Město, 110 00 Praha 1, Czech Republic</p>
        <p>Telephone: +420 704 470 207</p>
        <p>E-mail: <a href="mailto: sales@resistant.ai">sales@resistant.ai</a></p>

        <p><strong>Resistant AI s.r.o.</strong></p>
        </div>
      </Section>
    </Layout>
  )
}

export default PrivacyPolicy
